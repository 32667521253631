<template>
    <div class="d-flex flex-column min-vh-100">
        <!-- This is the template you should probably be using in all
                views of the site. Here we'll include Nav, Footer, Scrolltop, Cookies, etc. -->
        <Header />
        <Nuxt class="flex-1" />
        <Footer />
        <compare-lenders-popup />

        <!-- <client-only>
            <CookieConsent
                content="We use cookies."
                buttonText="Cool"
            />
        </client-only> -->

        <!-- <ScrollTop /> -->

        <!-- <Footer /> -->
    </div>
</template>
<script>
import Header from '@/components/includes/TheHeader.vue';
import Footer from '@/components/includes/TheFooter';
import CompareLendersPopup from '@/components/includes/CompareLendersPopup.vue';
// import { CookieConsent, ScrollTop } from '@swegaming-ab/vue-components'
export default {
    components: {
        Header,
        Footer,
        CompareLendersPopup
    },
    async middleware({ store, $prismic, route }) {
        await store.dispatch('fetchStates', { $prismic, route });
    }
};
</script>

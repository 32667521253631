<template>
    <li 
        class="hs-has-sub-menu nav-item"
        @click="toggle"
        @mouseenter="onEnter"
        @mouseleave="onLeave"
    >
        <a 
            class="hs-mega-menu-invoker nav-link dropdown-toggle fw-bold" 
            role="button" 
        >
            {{ dropdown.primary.drop_down_text }}
        </a>
        <div 
            :class="{ show: show }" 
            class="hs-sub-menu dropdown-menu" 
            style="min-width: 14rem;"
        >
            <nuxt-link 
                v-for="item in items"
                :key="`item${item.link_text}`"
                class="dropdown-item" 
                :to="$prismic.linkResolver(item.link_item)"
            >
                {{ item.link_text }}
            </nuxt-link>
        </div>
    </li>
</template>
<script>
export default {
    props: {
        dropdown: {
            type: Object,
            required: true
        },
        closeDropdowns: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            show: false
        };
    },
    computed: {
        items() {
            return this.dropdown.items;
        }
    },
    watch: {
        closeDropdowns() {
            this.show = false;
        }
    },
    methods: {
        toggle() {
            this.show = ! this.show;
        },
        onEnter() {
            if (window.innerWidth > 992) {
                this.show = true;
            }
        },
        onLeave() {
            if (window.innerWidth > 992) {
                this.show = false;
            }
        }
    }
};
</script>
<template>
    <div class="content-space-2 d-flex flex-column container">
        <div class="row justify-content-center">
            <div class="col col-lg-9">
                <h1 class="display-1 text-primary fst-italic">404</h1>
                <p class="fs-3">
                    {{ $translate('404_text', 'Something went wrong, go back to the homepage or navigate through the menu') }}
                </p>
                <nuxt-link to="/" class="btn btn-lg fw-bold btn-primary col-12 col-sm-4">
                    {{ $translate('404_homepage_link_text', 'Go to homepage') }}
                </nuxt-link>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    layout: 'site',
    head() {
        return {
            title: '404'
        };
    },
};
</script>
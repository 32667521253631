export default ({ app }, inject) => {
    inject('calculateMonthlyIncomeOrExpenses', calculateMonthlyIncomeOrExpenses);
    inject('calculateYearlyIncomeOrExpenses', calculateYearlyIncomeOrExpenses);
    return app;
};

export function calculateMonthlyIncomeOrExpenses(incomesOrExpenses) {
    let sum = 0;
    for (let incomeOrExpense of incomesOrExpenses) {
        if (incomeOrExpense.amount === '') {
            continue;
        }
        if (incomeOrExpense.frequency === 'quarterly') {
            sum += incomeOrExpense.amount / 3;
        } 
        else if (incomeOrExpense.frequency === 'yearly') {
            sum += incomeOrExpense.amount / 12;
        }
        else {
            sum += incomeOrExpense.amount;
        }
    }
    return sum;
}

export function calculateYearlyIncomeOrExpenses(incomesOrExpenses) {
    let sum = 0;
    for (let incomeOrExpense of incomesOrExpenses) {
        if (incomeOrExpense.amount === '') {
            continue;
        }
        if (incomeOrExpense.frequency === 'monthly') {
            sum += incomeOrExpense.amount * 12;
        } 
        else if (incomeOrExpense.frequency === 'quarterly') {
            sum += incomeOrExpense.amount * 4;
        }
        else {
            sum += incomeOrExpense.amount;
        }
    }
    return sum;
}
import Vue from 'vue';

import {  
    PrismicImage, 
} from '@swegaming-ab/vue-components';

import OutLink from '@/components/OutLink.vue';
import SiteWideLinks from '@/components/includes/footer/SiteWideLinks.vue';

const components = {
    PrismicImage,
    OutLink,
    SiteWideLinks
};

Object.entries(components).forEach(([name, component]) => {
    Vue.component(name, component);
});
export default [
    {
        country: 'Sweden',
        title: 'Kreditium.se',
        domain: 'https://kreditium.se',
        prismic: 'sv-se',
        hreflang: 'sv-se',
        icon: '/flags/sv.png'
    },
    {
        country: 'Norway',
        title: 'Kredittium.no',
        domain: 'https://kredittium.no',
        prismic: 'no',
        hreflang: 'no-no',
        icon: '/flags/no.png'
    },
    {
        country: 'Spain',
        title: 'Kreditium.es',
        domain: 'https://kreditium.es',
        prismic: 'es-es',
        hreflang: 'es-es',
        icon: '/flags/es.png'
    },
    {
        country: 'Estonia',
        title: 'Krediitium.ee',
        domain: 'https://krediitium.ee',
        prismic: 'et-ee',
        hreflang: 'et-ee',
        icon: '/flags/ee.png'
    },
    {
        country: 'Denmark',
        title: 'Kreditium.dk',
        domain: 'https://kreditium.dk',
        prismic: 'da-dk',
        hreflang: 'da-dk',
        icon: '/flags/dk.png'
    }
];
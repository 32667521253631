<template>
    <footer class="bg-dark">
        <div class="container content-space-t-3 content-space-b-3">
            <div class="row mb-9">
                <div class="col-lg-3 mb-5 mb-lg-0">
                    <nuxt-link to="/">
                        <img class="brand" :src="logo" alt="Logo" loading="lazy">
                    </nuxt-link>
                    <div class="mt-5 text-white">
                        <b>Compary AB</b> <br>
                        556955-1004 <br>
                        Stora Badhusgatan 18 <br>
                        411 21 Göteborg <br>
                        Sverige
                    </div>
                </div>
                <footer-link-section
                    v-for="(columnItem, index) in columnItems"
                    :key="index"
                    :title="columnItem.title"
                    :links="columnItem.links"
                />
            </div>

            <div class="row">
                <div class="col">
                    <p class="text-white-70 small mb-0">© Kreditium 2018 - {{ currentYear }}. All rights reserved.</p>
                </div>
            </div>

            <div class="mt-5">
                <a href="https://www.minskaco2.se/crt/dispcust/c/5699/l/3" title="CO2Neutral" rel="nofollow noopener" target="_blank">
                    <img src="@/assets/img/co-neutral-logo.png" alt="CO2Neutral logo" loading="lazy">
                </a>
            </div>
        </div>
        <div class="bg-secondary pt-3 text-light content-space-b-3">
            <div class="container">
                <ul class="list-inline d-flex justify-content-start justify-content-lg-center flex-wrap flex-lg-nowrap">
                    <li v-for="locale in locales" :key="locale.domain" class="text-center list-inline-item me-5">
                        <a :href="locale.domain" target="_blank" class="text-dark"><img :src="locale.icon" width="30px" class="me-2" loading="lazy"> {{ locale.title }}</a>
                    </li>
                </ul>
            </div>
        </div>
    </footer>
</template>
<script>
import FooterLinkSection from '@/components/FooterLinkSection.vue';
export default {
    components: {
        FooterLinkSection
    },
    data() {
        return {
            locales: require('@/config/locales.config').default,
            currentYear: new Date().getFullYear()
        };
    },
    computed: {
        logo() {
            if (this.$isSweden()) {
                return '/logo/se/logo-white.png';
            }
            else if (this.$isNorway()) {
                return '/logo/no/logo-white.png';
            }
            else if (this.$isSpain()) {
                return '/logo/es/logo-white.png';
            }
            else if (this.$isEstonia()) {
                return '/logo/ee/logo-white.png';
            }

            return '/logo/se/logo-white.png';
        },
        footerItems() {
            return this.$store.state.footer;
        },
        columnItems() {
            let columnItems = [];
            for (let i = 1; ;i++) {
                let titleKey = `column_${i}_title`;
                let linksKey = `column_${i}_items`;

                if (! this.footerItems[titleKey]) {
                    break;
                }
                columnItems.push({
                    title: this.footerItems[titleKey],
                    links: this.footerItems[linksKey].filter(linkItem => linkItem.link_text)
                });
            }
            return columnItems;
        }
    },
};
</script>

<template>
    <a 
        v-if="enabled"
        :href="outLink"      
        target="_blank" 
        rel="nofollow"
    >
        {{ text }} <i v-if="!disableIcon" class="bi bi-chevron-double-right ms-2" />
    </a>
</template>
<script>
export default {
    props: {
        document: {
            type: Object,
            required: false
        },
        link: {
            type: String,
            required: false
        },
        text: {
            type: String,
            required: false,

            default: function () {
                return this.$translate('cta_to_application', 'Till ansökan');
            }
        },
        disableIcon: {
            type: Boolean,
            required: false,
            default: false
        },
        placement: {
            type: String,
            required: false,
            default: null
        },
        position: {
            type: Number,
            required: false,
            default: null
        }
    },
    computed: {
        enabled() {
            return (this.document && this.document.data.redirect_enabled) || this.link;
        },
        outLink() {
            if (this.document) {
                if (this.placement && this.position && this.position > 0) {
                    return `/out/${this.document.id}/?placement=${this.placement}&position=${this.position}`;
                }
        
                if (this.placement) {
                    return `/out/${this.document.id}/?placement=${this.placement}`;
                }
        
                if (this.position && this.position > 0) {
                    return `/out/${this.document.id}/?position=${this.position}`;
                }
                return `/out/${this.document.id}/`;
            }
            else if (this.link) {
                return `/out/?link=${encodeURIComponent(this.link)}`;
            }

            return '';
        }  
    }
};
</script>
<template>
    <div class="bg-light mt-5">
        <div v-if="!show" class="text-center py-5">
            <a class="text-primary fs-3" role="button" @click="show = true;">
                {{ $translate('show_page_navigation', 'Visa sidnavigering') }} <i class="bi bi-arrow-down-circle-fill ms-2" />
            </a>
        </div>
        <div v-show="show" class="container content-space-t-1">
            <div class="row content-space-t-1">
                <div v-for="(column, index) in columns" :key="`footercolumn${index}`" class="col-lg-3 mb-5">
                    <h6 class="fw-normal text-uppercase">{{ column.primary.header }}</h6>
                    <ul class="list-unstyled">
                        <li v-for="(item, linkIndex) in column.items" :key="`footerlink${linkIndex}`" class="my-1">
                            <nuxt-link :to="$prismic.linkResolver(item.link_item)" class="">{{ item.link_text }}</nuxt-link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        footerId: {
            type: String,
            required: false,
            default: 'body'
        }
    },
    data() {
        return {
            show: false
        };
    },
    computed: {
        columns() {
            if (this.footerId === 'credit_card') {
                return this.$store.state.footer.credit_card_columns;
            }

            return this.$store.state.footer.body;
        }
    }
};
</script>
<template>
    <header id="header" class="shadow navbar navbar-expand-lg bg-white navbar-white sticky-top">
        <div class="mx-2 mx-md-5" :style="show && 'overflow: auto; max-height: 75vh;'">
            <nav class="js-mega-menu navbar-nav-wrap">
                <nuxt-link class="navbar-brand mb-1" to="/">
                    <img class="navbar-brand-logo" :src="logo" alt="Logo" @click="scrollToTop">
                </nuxt-link>
                <button class="navbar-toggler" :class="{'ms-2 order-1': hasCtaLink}" type="button">
                    <span v-if="! show" @click="toggle">
                        <i class="bi-list" />
                    </span>
                    <span v-else @click="toggle">
                        <i class="bi-x" />
                    </span>
                </button>
                <div class="collapse navbar-collapse order-4 order-lg-2" :class="{ show: show }">
                    <ul class="navbar-nav justify-content-center">
                        <template v-for="(item, index) in $store.state.navigation.body">
                            <li v-if="item.slice_type === 'link'" :key="`nav${index}`" class="nav-item">
                                <nuxt-link 
                                    class="nav-link fw-bold"
                                    :to="$prismic.linkResolver(item.primary.link_item)"
                                >
                                    {{ item.primary.link_text }}
                                </nuxt-link>
                            </li>

                            <Dropdown 
                                v-else
                                :key="`nav${index}`" 
                                :dropdown="item"
                                :close-dropdowns="show"
                            />
                        </template>
                    </ul>
                </div>
                <nuxt-link 
                    v-if="hasCtaLink"
                    :to="$prismic.linkResolver($store.state.navigation.cta_link)"
                    class="btn btn-primary btn-sm rounded-pill text-white py-1 px-3 align-self-center fw-bold ms-auto ms-lg-3 order-lg-2"
                >
                    {{ $store.state.navigation.cta_text }}
                </nuxt-link>
            </nav>
        </div>
    </header>
</template>
<script>

import Dropdown from './header/Dropdown.vue';
export default {
    components: {
        Dropdown
    },
    data() {
        return {
            show: false,
        };
    },
    computed: {
        logo() {
            if (this.$isSweden()) {
                return '/logo/se/logo-black.png';
            }
            else if (this.$isNorway()) {
                return '/logo/no/logo-black.png';
            }
            else if (this.$isSpain()) {
                return '/logo/es/logo-black.png';
            }
            else if (this.$isEstonia()) {
                return '/logo/ee/logo-black.png';
            }

            return '/logo/se/logo-black.png';
        },
        hasCtaLink() {
            return Boolean(this.$store.state.navigation.cta_link?.id && this.$store.state.navigation.cta_text);
        }
    },
    watch: {
        '$route.path': {
            handler: function() {
                this.show = false;
            }
        }
    },
    methods: {
        toggle() {
            this.show = ! this.show;
        },
        scrollToTop() {
            if (this.$route.path === '/') {
                window.scrollTo({top: 0, behavior: 'smooth'});
            }
        }
    }
};
</script>
<template>
    <div class="col-lg-3 mb-5 mb-md-0">
        <h5 class="text-white mb-3">{{ title }}</h5>
        <ul class="list-unstyled list-py-1 mb-0">
            <li v-for="(linkItem, index) in links" :key="index">
                <nuxt-link 
                    :to="$prismic.linkResolver(linkItem.link)" 
                    class="d-inline-block link-sm link-light text-primary-hover w-100"
                >
                    {{ linkItem.link_text }}
                </nuxt-link>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            required: true
        },
        links: {
            type: Array,
            required: true
        }
    }
};
</script>
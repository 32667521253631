<template>
    <transition name="compare">
        <div v-if="addedLenders.length" class="position-fixed bottom-0 end-0 zi-9999 max-width-100 shadow-lg bg-white p-3 p-sm-4 rounded rounded-end-0 rounded-bottom-0 wpx-250 wpx-sm-300">
            <ul class="list-unstyled max-hpx-120 max-hpx-sm-140 overflow-auto remove-scrollbar contain-overscroll mb-0">
                <li
                    v-for="(id, index) in addedLenders"
                    :key="id"
                    class="d-flex justify-content-between align-items-center gap-1 py-1"
                    :class="{'border-top': index !== 0}"
                >
                    <nuxt-link 
                        :to="$prismic.linkResolver(lender(id))"
                        class="d-flex flex-wrap align-items-center gap-1 text-dark text-primary-hover flex-grow-1"
                    >
                        <prismic-image
                            :img="lender(id).data.logo"
                            w="60"
                        />
                        <span class="overflow-ellipse fs-6 flex-1">{{ lender(id).data.title }}</span>
                    </nuxt-link>

                    <i 
                        class="bi bi-x fs-2 lh-1 text-danger-hover cursor-pointer"
                        @click="removeLender(id)"
                    />
                </li>
            </ul>

            <nuxt-link
                :to="compareRoute"
                class="btn btn-sm btn-primary w-100 mt-2"
            >
                {{ $translate('compare_lenders_cta_compare_button', 'Jämför {amount} lån').replace('{amount}', addedLenders.length) }}
            </nuxt-link>
        </div>
    </transition>
</template>

<script>
export default {
    data() {
        return {
            addedLenders: []
        };
    },
    computed: {
        compareRoute() {
            let comparePageRoute = this.$prismic.linkResolver(this.$store.state.settings.compare_lenders_page);
            comparePageRoute = comparePageRoute.endsWith('/') 
                ? comparePageRoute.slice(0, -1)
                : comparePageRoute;

            return `${comparePageRoute}?lender=${this.addedLenders.join('&lender=')}`;
        }
    },
    mounted() {
        this.addedLenders = JSON.parse(sessionStorage.getItem('lenders') || '[]');
        window.addEventListener('compare-lenders-changed', () => {
            this.addedLenders = JSON.parse(sessionStorage.getItem('lenders') || '[]');
        });
    },
    methods: {
        lender(id) {
            return this.$store.state.lenders[id];
        },
        removeLender(lenderIdToRemove) {
            const updatedLenderIds = this.addedLenders.filter(lenderId => lenderId !== lenderIdToRemove);
            sessionStorage.setItem('lenders', JSON.stringify(updatedLenderIds));
            window.dispatchEvent(new CustomEvent('compare-lenders-changed'));
        }
    }
};
</script>

<style lang="scss" scoped>
    .compare-enter-active,
    .compare-leave-active {
        transition: all .3s;
    }
    .compare-enter,
    .compare-leave-to {
        transform: translateY(120px);
    }
</style>